<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- <this-header /> -->
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="anan-set-nav-conttainer mt-150">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12">
              <side-bar class="d-none d-md-block" />
            </div>

            <div class="col-md-10 col-lg-10 col-sm-12">
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-button
                class="btn btn-gradient-success mb-1 ml-25 mr-50"
                :disabled="isDisabled"
                @click="Submit()"
              >
                <i class="far fa-save mr-50" /> {{ $t('saveData') }}
              </b-button>

              <b-row class="match-height">
                <b-col md="8">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t('details') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <validation-observer ref="addNew">
                        <b-row>
                          <b-col md="12">
                            <b-form-group
                              label-for="h-paecelCode"
                              label-cols="5"
                              label-cols-lg="5"
                              label-cols-md="5"
                              class="mb-50"
                            >
                              <template v-slot:label>
                                <span class="text-danger">*</span> {{ $t('bankAccount') }} :
                              </template>
                              <span v-if="itemsbank">
                                {{ $t("key-55") }}: {{ itemsbank.name_bank }}
                                <br>
                                {{ $t("accountName") }}:
                                {{ itemsbank.name_account }} <br>
                                {{ $t("accountNumber") }}:
                                {{ itemsbank.number_account }} <br>
                                {{ $t("branch") }}:
                                {{ itemsbank.branch ? itemsbank.branch : "-" }}
                                <br>
                                <!-- <div
                                class="text-warning"
                              > -->
                                <br>
                                <!-- <button
                                  class="btn btn-gradient-warning"
                                  @click="$bvModal.show('modal-1')"
                                >
                                  <span class="text-dark">{{ $t("edit") }}</span>
                                </button> -->
                              <!-- </div> -->
                              </span>
                              <b-button
                                v-else
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                variant="outline-primary p-1"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-50"
                                />
                                {{ $t('selectBankAccount') }}
                              </b-button>
                            </b-form-group>

                            <b-form-group
                              label-for="h-paecelCode"
                              label-cols="5"
                              label-cols-lg="5"
                              label-cols-md="5"
                              class="mb-50"
                            >
                              <template v-slot:label>
                                {{ $t('systemBalance') }} :
                              </template>
                              <div class="mt-50">
                                {{ Commas(Balance.balance) }} ฿
                              </div>

                            </b-form-group>

                            <b-form-group
                              label-for="h-paecelCode"
                              label-cols="5"
                              label-cols-lg="5"
                              label-cols-md="5"
                              class="mb-50"
                            >
                              <template v-slot:label>
                                <span class="text-danger">*</span>
                                {{ $t('withdrawalAmount') }} :
                              </template>
                              <validation-provider
                                #default="{ errors }"
                                name="h-paecelCode"
                                rules="required"
                              >
                                <b-input-group
                                  append="฿"
                                  class="input-group-merge"
                                >
                                  <b-form-input
                                    v-model="amount"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                  />
                                </b-input-group>
                                <small class="text-danger">{{
                                  errors[0] ? $t("requiredField") + "!" : ""
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                            <b-form-group
                              label-for="h-paecelCode"
                              label-cols-md="12"
                              class="mb-50"
                            >
                              <template v-slot:label>
                                <p class="text-danger text-center">
                                  {{ $t('1-2-3') }}
                                </p>
                              </template>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-modal
        id="modal-1"
        centered
        :ok-title="$t('key-124')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-secondary"
        size="lg"
        @ok="addBank()"
      >
        <template v-slot:modal-header>
          <!-- Custom Title -->
          <h5 class="modal-title mr-2">
            <span>{{ $t('selectBankAccount') }}</span>
            <!-- Custom Button on Title -->
            <!-- <b-button
              v-b-modal.modal-2
              size="sm"
              class="ml-2 btn-gradient-success"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-10"
              />
              {{ $t('addInfo') }}
            </b-button> -->
          </h5>
          <!-- <span v-if="itemBank.length < 2">เพิ่มบัญชีธนาคารและยืนยันบัญชีธนาคารสำหรับถอน
            <b-link :to="{name: 'my-profile'}">
              (คลิก)
            </b-link>
          </span> -->

          <b-button
            size="sm"
            class="close bg-light"
            aria-label="Close"
            variant="light"
            @click="hideModal"
          >
            <span aria-hidden="true">&times;</span>
          </b-button>
        </template>
        <div class="modal-body">
          <b-col md="12">
            <b-table
              responsive="sm"
              :fields="fieldsRelevant"
              :items="itemBank"
              show-empty
            >
              <template #empty="">
                <b-table-empty-slot>
                  <div class="text-center my-2">
                    <img
                      src="@/assets/images/anan-img/svg/doc_empty.svg"
                      alt="empty"
                      height="60px"
                    >
                    <p class="text-muted">
                      No Data
                    </p>
                  </div>
                </b-table-empty-slot>
              </template>

              <template #cell(select)="data">
                <b-form-checkbox
                  v-if="data.item.status === 2"
                  v-model="checkeditem"
                  :value="data.item"
                  :unchecked-value="null"
                />
              </template>

              <template #cell(status)="data">
                <b-badge
                  v-if="data.item.status === 1"
                  variant="warning"
                >
                  <span class="text-dark">{{ $t('pendingReview') }} Account </span>
                </b-badge>
                <b-button
                  v-if="data.item.status === 0 || data.item.status === 3"
                  v-b-modal.modal-verifyBank
                  size="sm"
                  pill
                  variant="gradient-success"
                  style="margin-left: 5px; padding: 3px 6px 3px 6px;width:80px;"
                  @click="verifyBank(data.item)"
                >
                  {{ $t('key-203') }}
                </b-button>
                <!-- <feather-icon
                  v-b-modal.modal-1
                  icon="Edit3Icon"
                  class="ml-1 text-primary cursor-pointer"
                  @click="EditType(item)"
                />
                <feather-icon
                  class="ml-1 text-danger cursor-pointer"
                  icon="TrashIcon"
                  @click="DeleteData(item)"
                /> -->
              </template>

              <!-- <template #cell(action)="data">
                <feather-icon
                  v-b-modal.modal-2
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />

                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="TrashIcon"
                  @click="DeleteData(data.item)"
                />
                <h1>{{ data.item.id }}</h1>
              </template> -->
            </b-table>
          </b-col>
        </div>
      </b-modal>

      <b-modal
        id="modal-verifyBank"
        cancel-variant="outline-secondary"
        :ok-title="$t('key-124')"
        :cancel-title="$t('cancel')"
        centered
        :title="$t('key-207')"
        @ok.prevent="SubmitVerifyBank()"
      >

        <div class="w-100 h-100">
          <div class="d-flex justify-content-center flex-wrap">
            <img
              src="/Bank2.png"
              width="100%"
            >

            <img
              src="/Bank1.png"
              width="100%"
            >
            <h3 class="font-weight-bold text-danger text-center">
              *ชื่อบัญชี และบัตรประชาชน ต้องตรงกันเท่านั้น
            </h3>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <input
              ref="fileInputBank"
              type="file"
              accept="image/*"
              style="display: none"
              @change="fileImageBank"
            >
            <input
              ref="cameraInputBank"
              type="file"
              accept="image/*"
              capture="camera"
              style="display: none"
              @change="fileImageBank"
            >
            <b-button
              style="width: auto; height: 100px"
              variant="relief-primary"
              class="mr-1"
              @click="openFileInputBank"
            >
              <feather-icon
                icon="FilePlusIcon"
                class="mb-1"
                size="30"
              /><br><div style="width: 100px;">
                {{ $t('key-208') }}
              </div>
            </b-button>
            {{ $t("or") }}
            <b-button
              style="width: auto; height: 100px"
              variant="relief-success"
              class="ml-1"
              @click="openCameraBank"
            >
              <feather-icon
                icon="CameraIcon"
                class="mb-1"
                size="30"
              /><br>
              <div style="width: 100px;">
                {{ $t('key-209') }}
              </div>
            </b-button>
          </div>

          <div class="mt-2 d-flex justify-content-center">
            <span
              v-if="imageOrBank"
              class="preview-image-containerv2 mr-2 d-flex justify-content"
            >
              <img
                :src="imageOrBank"
                alt="Preview"
                class=""
                @click="showPicture(imageOr)"
              >
              <div class="preview-image-overlays p-0">
                <i
                  class="fas fa-times-circle cursor-pointer"
                  @click="deleteImageorderBank()"
                />
              </div>
            </span>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="modal-2"
        cancel-variant="outline-secondary"
        :ok-title="$t('key-124')"
        :cancel-title="$t('cancel')"
        centered
        :title="$t('addBankAccount')"
        @ok.prevent="addNewbank()"
      >
        <validation-observer ref="addNewbank">
          <b-form>
            <b-form-group>
              <span class="text-danger">*</span> {{ $t('bankAccount') }} :
              <v-select
                v-model="bank_obj"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="itemBankList"
              >
                <template #option="{ name, path_photo }">
                  <img
                    :src="`${path_photo}`"
                    :alt="name"
                    height="30"
                    class="mr-50 rounded-sm"
                  >
                  <span>{{ name }}</span>
                </template>

                <template #selected-option="{ name, path_photo }">
                  <img
                    :src="`${path_photo}`"
                    :alt="name"
                    height="30"
                    class="mr-50"
                  >
                  <span>{{ name }}</span>
                </template>

              </v-select>
            </b-form-group>
            <b-form-group>
              <span class="text-danger">*</span> {{ $t('accountName') }} :
              <validation-provider
                #default="{ errors }"
                name="name_account"
                rules="required"
              >
                <b-form-input
                  id="h-name_bank"
                  v-model="name_account"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("requiredField") + "!" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <span class="text-danger">*</span> {{ $t('accountNumber') }} :
              <validation-provider
                #default="{ errors }"
                name="number_account"
                rules="required"
              >
                <b-form-input
                  id="h-number_account"
                  v-model="number_account"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t("requiredField") + "!" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group>
          {{ $t('branch') }} :
          <b-form-input type="text" />
        </b-form-group> -->
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- <b-modal
        id="modal-edit"
        cancel-variant="outline-secondary"
        :ok-title="$t('key-124')"
        :cancel-title="$t('cancel')"
        centered
        :title="$t('key-125')"
      >
        <b-form>
          <b-form-group>
            <span class="text-danger">*</span> {{ $t('bank') }} : -->
      <!-- <label for="customerCode">{{ $t('bank') }} : <span class="text-danger">*</span></label> -->
      <!-- <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="itemBankList"
            >
              <template #option="{ name, path_photo }">
                <img
                  :src="`${path_photo}`"
                  :alt="name"
                  height="30"
                  class="mr-50 rounded-sm"
                >
                <span>{{ name }}</span>
              </template>

              <template #selected-option="{ name, path_photo }">
                <img
                  :src="`${path_photo}`"
                  :alt="name"
                  height="30"
                  class="mr-50"
                >
                <span>{{ name }}</span>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group>
            <span class="text-danger">*</span> {{ $t('accountName') }} :
            <b-form-input type="text" />
          </b-form-group>
          <b-form-group>
            <span class="text-danger">*</span> {{ $t('accountNumber') }} :
            <b-form-input type="text" />
          </b-form-group>
          <b-form-group>
            {{ $t('branch') }} :
            <b-form-input type="text" />
          </b-form-group>
        </b-form>
      </b-modal> -->
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BInputGroup,
  BModal,
  BTable,
  BOverlay,
  BLink,
  // BFormRadio,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import SideBar from '@/views/customer/profile/component/SideBar.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment-timezone'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BInputGroup,
    BModal,
    BTable,
    BOverlay,
    // BFormRadio,
    BFormCheckbox,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BLink,
    BBadge,
  },
  data() {
    return {
      required,
      keyword: null, // วลีช่วยจำ
      credit: 100, // ยอดเงิน
      itemBank: [
        // { name: 'ธนาคารกสิกรไทย', path_photo: '/bankIcon/KBANK.png' },
        // { name: 'ธนาคารไทยพานิชญ์', path_photo: '/bankIcon/scb.png' },
        // { name: 'ธนาคารกรุงศรีอยุธยา', path_photo: '/bankIcon/BAY.png' },
        // { name: 'ธนาคารกรุงไทย', path_photo: '/bankIcon/KTB.png' },
      ],

      Transaction: [
        {
          bankName: 'ธนาคารกสิกรไทย',
          Name: 'ก๋วยเตี๋ยว น้ำตก',
          BankNumber: '123-123-123',
          branch: '-',
        },
      ],
      Balance: [],
      itemBankList: [],
      bank_obj: null,
      name_account: null,
      number_account: null,
      id: null,
      checkeditem: null,
      itemsbank: null,
      amount: 0,
      showOver: false,
      imageOr: null,
      imageOrBank: null,
      items: null,
      isDisabled: false,

    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'select', label: '', thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'name_bank', label: this.$t('key-55'), thStyle: { width: '20%' }, thClass: 'text-center',
        },
        { key: 'name_account', label: this.$t('accountName'), thStyle: { width: '20%' } },
        { key: 'number_account', label: this.$t('accountNumber'), thStyle: { width: '20%' } },
        { key: 'status', label: 'สถานะ', thStyle: { width: '30%' } },
        // { key: 'branch', label: this.$t('branch'), thStyle: { width: '20%' } },
        // { key: 'action', label: this.$t('manage'), thStyle: { width: '10%' } },
      ]
    },
  },
  mounted() {
    this.getBalance()
    this.getSelect()
    this.getBank()
    this.getData()
  },
  methods: {
    openFileInputBank() {
      this.$refs.fileInputBank.click()
    },
    openCameraBank() {
      this.$refs.cameraInputBank.click()
    },
    deleteImageorderBank() {
      this.file_cusBank = null
      this.imageOrBank = null
    },
    async getData() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/show')
        this.items = res
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    fileImageBank(event) {
      if (event) {
        this.file_cusBank = event.target.files

        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cusBank[0]
        if (!filepath) {
          this.imageOrBank = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOrBank = e.target.result
            // eslint-disable-next-line no-undef

            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    verifyBank(data) {
      if (data) {
        this.itemsbank = data
      } else {
        this.itemsbank = null
      }
      this.$bvModal.show('modal-verifyBank')
    },
    SubmitVerifyBank() {
      if (this.items.verify_status !== 2) {
        this.SwalError(this.$t('key-210'))
      } else if (!this.imageOrBank) {
        this.SwalError(this.$t('key-212'))
      } else {
        const obj = {
          id: this.itemsbank._id,
          status: 1,
          file_bank: this.imageOrBank,
        }
        this.$http.post('/Refill/StoreBank', obj)
          .then(() => {
            this.getBank()
            this.Success(this.$t('1-2'))
            this.$bvModal.hide('modal-verifyBank')
            this.imageOrBank = null
          })
          .catch(err => {
            console.log(err)
            this.SwalError(err)
          })
      }
    },
    async getBalance() {
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        this.Balance = res
        // console.debug(res)
      } catch (err) {
        console.log(err)
      }
    },
    async getBank() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Refill/getBankcus')
        this.itemBank = res
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    Submit() {
      this.isDisabled = true
      if (!this.itemsbank) {
        this.SwalError('เลือกบัญชีสำหรับถอนเงิน')
        this.isDisabled = false
      } else if (this.amount <= 0) {
        this.SwalError('กรอกจำนวนที่ต้องการถอน')
        this.isDisabled = false
      } else if (this.Balance.balance < this.amount) {
        this.SwalError('จำนวนเงินไม่เพียงพอ')
        this.isDisabled = false
      } else {
        this.$refs.addNew.validate().then(success => {
          if (success) {
            const bankcode = this.itemBankList.find(ele => ele.bankcode === this.itemsbank.bank_code)
            const obj = {
              amount: this.amount,
              acc_no: this.itemsbank.number_account,
              cus_bank: this.itemsbank.name_account,
              bank: this.itemsbank.bank_code,
              bankname: this.itemsbank.name_bank,
              path_photo: bankcode.path_photo,
            }
            this.$http.post('/Withdraw/StoreWithdraw', obj)
              .then(response => {
                if (response.data.status) {
                  this.Success('บันทึกข้อมูลเรียบร้อย')
                  this.$router.push({ name: 'balance' })
                  this.isDisabled = false
                }
              })
              .catch(err => {
                this.SwalError(err.response.data.message)
                this.isDisabled = false
              })
          }
        })
      }
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            name_bank: this.bank_obj.name,
            bank_code: this.bank_obj.bankcode,
            name_account: this.name_account,
            number_account: this.number_account,
            branch: this.branch,
          }
          this.$http
            .post('/Refill/StoreBank', obj)
            .then(() => {
              this.$bvModal.hide('modal-2')
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.getBank()
            })
            .catch(err => {
              this.SwalError(err.response.data.message)
              console.log(err)
            })
        }
      })
      // console.debug(this.bank_obj)
    },
    addBank() {
      this.itemsbank = this.checkeditem
    },
    EditType(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.name_bank = data.name_bank
        this.name_account = data.name_account
        this.number_account = data.number_account
        this.branch = data.branch
        const FindBank = this.itemBankList.find(
          ele => ele.bankcode === data.bank_code,
        )
        this.bank_obj = FindBank
      } else {
        this.id = null
        this.name_bank = null
        this.name_account = null
        this.number_account = null
        this.branch = null
        this.bank_obj = null
      }
      this.$bvModal.show('modal-2')
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('ลบข้อมูลบัญชีธนาคารนี้?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http
              .post('/Refill/DeleteBank', obj)
              .then(() => {
                this.getBank()
                this.Success('ลบบัญชีธนาคารเรียบร้อย')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    hideModal() {
      this.$bvModal.hide('modal-1')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
